<script>

    import Layout from '../layouts/main.vue'

    import PageHeader from '../../components/page-header.vue';
    import TrainInfoVue from './TrainInfo.vue';
    // import TravelerFormVue from './TravelerForm.vue';
    // import TravelerInfoVue from './TravelerInfo.vue';
    // import PriceBreakdownVue from './PriceBreakdown.vue';

    import { mapActions, mapGetters } from 'vuex';
    import RailUtils from "../../utils/RailUtils"
    // import RailAPI from '../../api/RailAPI';

    export default {
        name: 'CartVue',
        components: {
            Layout,
            PageHeader,
            TrainInfoVue,
            // TravelerFormVue,
            // TravelerInfoVue,
            // PriceBreakdownVue,
        },
        data () {
            return {
                title: "Cart",
                items: [
                    {
                        text: "Cart",
                        active: true,
                    }
                ],
                isOverLayShow: false,
                alertSettings: {
                    dismissCountDown: 0,
                    countDownSecs: 4,
                    title: '',
                    alertMsg: '',
                    alertVariant: 'warning'
                },
                componentName: "TravelerFormVue",
                travelerQuantity: {
                },
                journeyDetails: {
                },
                travelerFormValues: [],
                travelersForm: [
                ],
                correlationID: '',
                travelers: [
                ],
                confirmBookingRQ: '',
                isInitiateStep: true,
                isQuoteStep: false,
                isPreBooked: false,
                isConfirmed: false,
                bookedPrice:{
                    currencyCode: '',
                    totalAmount: 0
                },
                cart: [],
                selectedProductIndex: [],
                selectedProductsRph: [],
                selectedProductFromCart: [],
                refreshCartStatusBtnDisabled: false,
                isP2pConditionsModalVisible: false,
                isPassConditionsModalVisible: false,
                displayedPassConditions: [],
                hasExpiredItems: false,
            }
        },
        computed: {
            ...mapGetters('rail', [
                'selectedOutboundTicket',
                'selectedInboundTicket',
                'isRoundTrip',
                'searchInputValues',
                'bookInitiateRS',
                'bookTicketRS',
            ]),
            ...mapGetters('account', [
                'accountInfo',
                'loginStatusRS',
                'logedIn'
            ]),
            ...mapGetters('booking', [
                'shoppingCart',
                'cartNumber',
            ])
        },
        watch: {
            bookInitiateRS() {
                //initiate
                this.isOverLayShow = false
                if(this.bookInitiateRS.success){
                    this.isInitiateStep = false
                    this.isQuoteStep = true
                    this.correlationID = this.bookInitiateRS.correlationID
                    let reservations = this.bookInitiateRS.railReservations

                    let bookFromCart = {} 
                    bookFromCart.items = this.selectedProductFromCart
                    bookFromCart.indexes = this.selectedProductIndex
                    bookFromCart.reservations = reservations
                    bookFromCart.correlationID = this.correlationID

                    // 下面代码是不需要勾选，默认全部
                    // bookFromCart.items = shoppingCart
                    // bookFromCart.reservations = reservations
                    // bookFromCart.correlationID = this.correlationID


                    this.updateItemForCart({
                        bookFromCart: bookFromCart
                    })

                    this.$router.push({
                        name: 'cartBook'
                    })
                }
                else{
                    this.alertSettings.title = this.bookInitiateRS.errorTitle
                    this.alertSettings.alertMsg = this.bookInitiateRS.errorMsg
                    this.alertSettings.alertVariant = 'danger'
                    this.alertSettings.dismissCountDown = this.alertSettings.countDownSecs+10
                }
            },
        },
        created() {
            this.toLoadCart()
            this.toRefreshCartStatus()
        },
        methods: {
            ...mapActions('rail',[
                'bookTicket',
                'bookInitiate',
                'updateItemForCart'
            ]),
            ...mapActions('booking',[
                'removeGoodsFromCart',
                'loadGoodsFromCart'
            ]),
            countDownChanged(dismissCountDown) {
                this.alertSettings.dismissCountDown = dismissCountDown
            },
            backTo: function() {
                
            },
            toInitiate(){
                this.resetDatas()
                // this.toCheckExpiredItems()

                let isAllOk = true
                // isAllOk = isAllOk && !this.hasExpiredItems

                let islogedIn = this.logedIn
                let accountInfoFromSession = JSON.parse(sessionStorage.getItem('accountInfo'))
                let username = accountInfoFromSession.id   

                let rphs = []
              
                //下面代码是需要勾选操作的
                this.selectedProductIndex.forEach(idxItem => {
                    let product = this.shoppingCart[idxItem]
                    this.selectedProductFromCart.push(product)

                    if(product.inbound){
                        rphs.push(product.outbound.rph)
                        rphs.push(product.inbound.rph)
                    }else if(product.outbound){
                        rphs.push(product.outbound.rph)
                    }
                    else{
                        rphs.push(product.pass.rph)
                    }
                    
                })
                // 下面代码是不需要勾选，默认全部
                // shoppingCart.forEach(item => {
                //     if(item.inbound){
                //         rphs.push(item.outbound.rph)
                //         rphs.push(item.inbound.rph)
                //     }else if(item.outbound){
                //         rphs.push(item.outbound.rph)
                //     }
                //     else{
                //         rphs.push(item.pass.rph)
                //     }
                // })
                if(rphs.length == 0){
                    isAllOk = false
                    this.alertSettings.title = 'Warning!'
                    this.alertSettings.alertMsg = 'Please at least select one item.'
                    this.alertSettings.dismissCountDown = this.alertSettings.countDownSecs+10
                }

                if(isAllOk){
                    if(islogedIn) {
                        let bookRQ = RailUtils.getBookInitiateRQ({
                            rphs: rphs,
                            username: username
                        })
                        this.isOverLayShow = true
                        this.bookInitiate(bookRQ)
                    }else {
                        this.alertSettings.title = 'Sorry!'
                        this.alertSettings.alertMsg = 'Please log in.'
                        this.alertSettings.dismissCountDown = this.alertSettings.countDownSecs+10
                    }
                }
                
            },
            updateSelectedProducts(value, index){
                if(value == 0){
                    if(this.selectedProductIndex.includes(index)){
                        this.selectedProductIndex.splice(this.selectedProductIndex.indexOf(index), 1)
                    }
                }else{
                    this.selectedProductIndex.push(index)
                }
                // console.log(JSON.stringify(this.selectedProductIndex))
            },
            toRemoveItems(removedIdx){
                // let thisTemp = this;
                this.removeGoodsFromCart({indexes: [removedIdx]}).then(()=> {
                    // thisTemp.toCheckExpiredItems()
                });
            }, 
            resetDatas(){
                this.selectedProductFromCart = []
                this.hasExpiredItems = false
                //reset alert messages
                this.alertSettings.title = ''
                this.alertSettings.alertMsg = ''
                this.alertSettings.alertVariant = 'warning'
            
            },
            toLoadCart(){
                this.loadGoodsFromCart()
            },
            toRefreshCartStatus() {
                this.refreshCartStatusBtnDisabled = true;
                this.loadGoodsFromCart()
                this.refreshCartStatusBtnDisabled = false;
            },
                toCheckExpiredItems(){
                    this.hasExpiredItems = false;
                    this.shoppingCart.forEach(element => {
                        if(element.expired){
                            this.hasExpiredItems = true
                        }
                    })
            },
            toViewConditions(type, item) {
                this.itemToViewConditions = {}
                this.displayedPassConditions = []

                if(type === 'P2P'){
                    this.itemToViewConditions = item
                    this.isP2pConditionsModalVisible = true
                }

                if(type === 'PASS'){
                    item.pass.conditions.forEach(element => {
                        let con = {}
                        if(element.code == 'CARD_INTRODUCTION'){
                            con.title = 'Card Introduction'
                            con.stringValue = element.stringValue
                            this.displayedPassConditions.push(con)
                        }
                        if(element.code == 'CARD_SALES'){
                            con.title = 'Card Sales'
                            con.stringValue = element.stringValue
                            this.displayedPassConditions.push(con)
                        }
                        if(element.code == 'CARD_LABEL'){
                            con.title = 'Card Label'
                            con.stringValue = element.stringValue
                            this.displayedPassConditions.push(con)
                        }

                        if(element.code == 'GLOBAL_INTRODUCTION_DETAILS'){
                            con.title = 'Global Introduction Details'
                            con.stringValue = element.stringValue
                            this.displayedPassConditions.push(con)
                        }
                        if(element.code == 'GLOBAL_INTRODUCTION'){
                            con.title = 'Global Introduction'
                            con.stringValue = element.stringValue
                            this.displayedPassConditions.push(con)
                        }
                        if(element.code == 'GLOBAL_DESCRIPTION'){
                            con.title = 'Global Description'
                            con.stringValue = element.stringValue
                            this.displayedPassConditions.push(con)
                        }
                        if(element.code == 'INCLUDES'){
                            con.title = 'Includes'
                            con.stringValue = element.stringValue
                            this.displayedPassConditions.push(con)
                        }
                        if(element.code == 'PASS_RANGE_SALES'){
                            con.title = 'Pass Range Sales'
                            con.stringValue = element.stringValue
                            this.displayedPassConditions.push(con)
                        }
                        if(element.code == 'PASS_RANGE_AFTER_SALES'){
                            con.title = 'Pass After Sales'
                            con.stringValue = element.stringValue
                            this.displayedPassConditions.push(con)
                        }  
                        if(element.code == 'TICKETING_METHOD'){
                            con.title = 'Ticketing Method'
                            con.stringValue = element.stringValue
                            this.displayedPassConditions.push(con)
                        }
                        if(element.code == 'PAXTYPE_SALES'){
                            con.title = 'Paxtype Sales'
                            con.stringValue = element.stringValue
                            this.displayedPassConditions.push(con)
                        }
                    })
                    this.isPassConditionsModalVisible = true  
                }
                
                
            },
        }
    }

</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"></PageHeader>
    <b-overlay :show="isOverLayShow" rounded="sm">
    <b-row>
        <b-col>
        <b-card>
            <!-- <h5>There are <b>{{cart.length}}</b> items</h5>
            <p>1. You could book any of them individually.</p>
            <p>2. You could also book one P2P ticket and one PASS ticket together.</p>
            <p>3. You will be charged EUR 7.45 for each CHECKOUT.</p>
             -->
            <div>
                <p class="font-size-16">You will be charged <span class="text-danger">EUR 7.45</span> for each CHECKOUT.</p>
            </div>
            <div class="mt-4">
                <b-button variant="success" @click.prevent="toRefreshCartStatus">
                    <template>
                        <b-spinner small v-if="refreshCartStatusBtnDisabled"></b-spinner>
                        <span class="">{{refreshCartStatusBtnDisabled ? 'Loading...' : 'Refresh cart status'}}</span>
                    </template>
                </b-button>
            </div>
        </b-card>
    </b-col>
    </b-row>
    <b-row v-for="(item, index) in shoppingCart" :key="`product-${index}`">

        <!-- is a pass -->
        <b-col v-if="item.pass">
            <b-card>
                <div class="d-flex justify-content-center">
                    <!-- <b-form-checkbox
                    :id="`product-checkbox-${index}`"
                    :value="item"
                    unchecked-value="0"
                    @change="cValue => {updateSelectedProducts(cValue, index)}"
                    >
                        Product-{{index+1}} ({{ 'PASS' }})
                    </b-form-checkbox> -->
                    <div class="mb-n4">
                        <b-button variant="light" href="#" @click="toRemoveItems(index)" class="rounded-pill" title="Remove">
                            <span class="text-secondary" v-if="item.expired">EXPIRED</span>
                            <span class="ml-1 text-danger" v-else>{{ item.expireIn + 'm' }}</span>
                            <i class="mdi mdi-trash-can ml-1 h5 text-secondary"></i>
                        </b-button>
                    </div>
                    <div class="mb-n4" v-if="!item.expired">
                        <b-badge variant="danger" pill :title="`Expire in ${item.expireIn}m`">
                            <i class="mdi mdi-clock text-light"></i>
                        </b-badge>
                    </div>
                </div>
                <div class="border-bottom d-flex justify-content-between">
                    <h5 class="font-size-16 text-primary">{{item.pass.label}}</h5>
                    <b-form-checkbox
                    :id="`product-checkbox-${index}`"
                    :value="item"
                    unchecked-value="0"
                    @change="cValue => {updateSelectedProducts(cValue, index)}"
                    >
                        Product-{{index+1}} ({{ 'PASS' }})
                    </b-form-checkbox>
                </div>
                <b-card>
                    <div >
                        <ul class="list-unstyled">
                            <li>{{item.pass.passType == 'CONSECUTIVE' ? 'Continuous Travel' : 'Flexible Travel'}}</li>
                            <li v-html="item.pass.includes"></li>
                        </ul>
                    </div>
                
                    <div class="mt-2">
                        <ul class="list-unstyled">
                            <li>
                                <b-icon icon="circle-fill" variant="danger"></b-icon>&nbsp;
                                <b>From &nbsp; &nbsp;{{item.pass.validFrom}}</b>
                            </li>
                            <li>
                                <b-icon icon="circle-fill" variant="danger"></b-icon>&nbsp;
                                <b>{{item.pass.validity}} 
                                    {{item.pass.passType == 'CONSECUTIVE' ? 'continuous ' : 'flexible '}} pass - 
                                    
                                    {{item.pass.class == 'STANDARD' ? '2nd ' : '1st '}} Class - 
                                    
                                    {{item.pass.sellingCurrencyCode}}&nbsp;&nbsp; {{item.pass.sellingAmount}}</b>
                            </li>
                        </ul>
                    </div>
                </b-card>
                <div class="border p-2 d-flex justify-content-between">
                    <div class="d-flex">
                        <div v-for="passengerItem,passengerItemIdx in item.pass.passengers" :key="`pass-passenger-item-${passengerItemIdx}`"
                        class="ml-3 text-info"
                        >
                            <span>{{ passengerItem.quantity + ' x ' +  passengerItem.code }}</span>
                        </div>
                        <div class="ml-5">
                            <a href="#" @click="toViewConditions('PASS', item)"><span>Terms and Conditions</span></a>
                            
                        </div>
                    </div>
                    <div class="">
                        <span class="text-danger">{{item.pass.sellingCurrencyCode}} {{item.pass.sellingAmount}}</span>
                    </div>  
                </div> 
            </b-card>
        </b-col>

        <!-- is a p2p -->
        <b-col v-if="item.outbound">
            <b-card>
                <div class="d-flex justify-content-center">
                    <!-- <b-form-checkbox
                    :id="`product-checkbox-${index}`"
                    :value="item"
                    unchecked-value="0"
                    @change="cValue => {updateSelectedProducts(cValue, index)}"
                    >
                        Product-{{index+1}} ({{ 'P2P' }})
                    </b-form-checkbox> -->
                    <div class="mb-n4">
                        <b-button variant="light" href="#" @click="toRemoveItems(index)" class="rounded-pill" title="Remove">
                            <span class="text-secondary" v-if="item.expired">EXPIRED</span>
                            <span class="ml-1 text-danger" v-else>{{ item.expireIn + 'm' }}</span>
                            <i class="mdi mdi-trash-can ml-1 h5 text-secondary"></i>
                        </b-button>
                    </div>
                    <div class="mb-n4" v-if="!item.expired">
                        <b-badge variant="danger" pill :title="`Expire in ${item.expireIn}m`">
                            <i class="mdi mdi-clock text-light"></i>
                        </b-badge>
                    </div>
                </div>
                <div class="border-bottom d-flex justify-content-between">
                    <h5 class="font-size-16 text-success">
                        {{item.outbound.originStation}}
                        <i v-if="item.inbound" class="mdi mdi-arrow-left-right-bold"></i>
                        <i v-else class="mdi mdi-arrow-right-bold"></i> 
                        {{item.outbound.destinationStation}}
                    </h5>
                    <b-form-checkbox
                    :id="`product-checkbox-${index}`"
                    :value="item"
                    unchecked-value="0"
                    @change="cValue => {updateSelectedProducts(cValue, index)}"
                    >
                        Product-{{index+1}} ({{ 'P2P' }})
                    </b-form-checkbox>
                </div>
                <TrainInfoVue v-if="item.inbound" :selected-outbound-ticket="item.outbound" :selected-inbound-ticket="item.inbound"/>
                <TrainInfoVue v-else :selected-outbound-ticket="item.outbound"/>
                <div class="border p-2 d-flex justify-content-between">
                    <div class="d-flex">
                        <div v-for="priceItem,priceItemIdx in item.outbound.priceBreakdown" :key="`price-item-${priceItemIdx}`"
                        class="ml-3 text-info"
                        >
                            <span>{{ priceItem.quantity + ' x ' +  priceItem.code }}</span>
                        </div>
                        <div class="ml-5">
                            <a href="#" @click="toViewConditions('P2P', item)"><span>Terms and Conditions</span></a>
                            
                        </div>
                    </div>
                    <div class="">
                        
                        <span class="text-danger" v-if="item.inbound">
                            {{item.outbound.sellingCurrencyCode}} 
                            {{(Number(item.outbound.sellingTotalAmount) + Number(item.inbound.sellingTotalAmount)).toFixed(2)}}
                        </span>
                        <span class="text-danger" v-else>{{item.outbound.sellingCurrencyCode}} {{item.outbound.sellingTotalAmount}}</span>
                    </div>  
                </div> 
            </b-card>
        </b-col>
    </b-row>
<!--    <b-row>-->
<!--        <b-col>-->
<!--            <div class="text-center text-danger" v-show="hasExpiredItems">-->
<!--                <p>Please remove expired items.</p>-->
<!--            </div>-->
<!--        </b-col>-->
<!--    </b-row>  -->
    <b-row>
        <b-col>
            <div class="p-2 d-flex justify-content-end">
                <div class="ml-2">
                    <b-button variant="success" @click="toInitiate">Continue to Passengers</b-button>
                </div>
            </div>
        </b-col>
    </b-row>
    </b-overlay>
    <b-alert
    :show="alertSettings.dismissCountDown"
    class="position-fixed fixed-top m-0 rounded-0 text-center"
    style="z-index: 2000;"
    :variant="alertSettings.alertVariant"
    dismissible
    @dismiss-count-down="countDownChanged"
    >
<!--        <div v-html="alertSettings.title"></div>-->
        <div v-html="alertSettings.alertMsg"></div>
    </b-alert>

    <b-modal id="cart-p2p-conditions-modal"
    v-if="isP2pConditionsModalVisible"
    size="lg"
    v-model="isP2pConditionsModalVisible"
    centered scrollable  hide-footer title="Ticket Conditions">
        <b-tabs card>
            <b-tab title="Outbound" active>
                <div class="my-2" v-for="pbdItem, indx in itemToViewConditions.outbound.priceBreakdown" 
                :key="`outbound-con-content-${indx}`">
                    <span class="text-success">{{pbdItem.quantity+' x '}}{{pbdItem.code}}</span>
                    <div v-for="conItem, iiddxx in pbdItem.conditions" :key="`outbound-con-p-${iiddxx}`">
                        <p class="text-success">
                            {{itemToViewConditions.outbound.trainSegments[iiddxx].departureStation}}
                            <span class="mx-1">to</span>
                            {{itemToViewConditions.outbound.trainSegments[iiddxx].arrivalStation}}</p>
                        <p class="text-success">{{itemToViewConditions.outbound.trains[iiddxx]}}</p>
                        <p v-html="conItem"></p>
                    </div>
                </div>      
            </b-tab>
            <b-tab title="Inbound" v-if="itemToViewConditions.inbound">
                <div class="my-2" v-for="pbdItem, indx in itemToViewConditions.inbound.priceBreakdown" 
                :key="`inbound-con-content-${indx}`">
                    <span class="text-success">{{pbdItem.quantity+' x '}}{{pbdItem.code}}</span>
                    <div v-for="conItem, iiddxx in pbdItem.conditions" :key="`inbound-con-p-${iiddxx}`">
                        <p class="text-success">
                            {{itemToViewConditions.inbound.trainSegments[iiddxx].departureStation}}
                            <span class="mx-1">to</span>
                            {{itemToViewConditions.inbound.trainSegments[iiddxx].arrivalStation}}</p>
                        <p class="text-success">{{itemToViewConditions.inbound.trains[iiddxx]}}</p>
                        <p v-html="conItem"></p>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
        
    </b-modal>

    <b-modal id="cart-pass-conditions-modal"
    v-if="isPassConditionsModalVisible"
    size="lg"
    v-model="isPassConditionsModalVisible"
    centered scrollable  hide-footer title="Ticket Conditions">
        <div 
        class="mt-2"
        v-for="conItem, idx in displayedPassConditions" :key="`con-${idx}`">
            
            <p class="text-success"><b>{{conItem.title}}</b></p>
            <p v-html="conItem.stringValue"></p>
        </div>
    </b-modal>

    </Layout>
</template>

<style scoped>

</style>